import {
  MANAGE_JOB_ERROR,
  MANAGE_JOB_REQUEST,
  MANAGE_JOB_SUCCESS,
  MANAGE_SCHEDULER_ERROR,
  MANAGE_SCHEDULER_REQUEST,
  MANAGE_SCHEDULER_SUCCESS,
} from "../actions";

export const manageSchedulerRequest = (request) => ({
  type: MANAGE_SCHEDULER_REQUEST,
  payload: request,
  request_type: request.request_type,
});
export const manageSchedulerSuccess = (response, request_type) => ({
  type: MANAGE_SCHEDULER_SUCCESS,
  payload: response,
  request_type: request_type,
});
export const manageSchedulerError = (message) => ({
  type: MANAGE_SCHEDULER_ERROR,
  payload: message,
});

export const manageJobRequest = (request) => ({
  type: MANAGE_JOB_REQUEST,
  payload: request,
  request_type: request.request_type,
});
export const manageJobSuccess = (response, request_type) => ({
  type: MANAGE_JOB_SUCCESS,
  payload: response,
  request_type: request_type,
});
export const manageJobError = (message) => ({
  type: MANAGE_JOB_ERROR,
  payload: message,
});
