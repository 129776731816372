import { Row, Col, Form, Input, Button, message } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { pageRoutes } from "../../helpers/constants";
import { connect } from "react-redux";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { MANAGE_AUTH_REQUEST } from "../../redux/actions";
import { manageAuthRequest } from "../../redux/auth/action";
import { SwalError, SwalSuccess } from "../../components/Swal";
const Forgot = (props) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const {
    login_info,
    auth_loading,
    auth_error,
    auth_success,
    auth_action,
    auth_request_type,
  } = props.auth_reducer;
  useEffect(() => {
    if (auth_action == MANAGE_AUTH_REQUEST) {
      if (auth_request_type == "FORGOT_PASSWORD") {
        if (auth_success) {
          SwalSuccess(
            "Reset Link has been sent to your registered email address"
          );
        } else if (auth_error) {
          SwalError(JSON.stringify(auth_error));
        }
      }
    }
  }, [auth_loading]);

  return (
    <LoginContainer className="h-100">
      <Row className="h-100" gutter={10}>
        <Col xs={16} lg={16} className="d-none d-lg-block">
          <div className="bg-image" />
        </Col>
        <Col xs={6} lg={6} className="shape-image">
          <div className="d-flex w-100 h-100 justify-content-center align-items-center position-relative">
            <div className="w-100 login-form ">
              <div className="compony-logo">
                <img
                  src={
                    "https://prabhuclinics.medicorm.com/assets/images/brand.png"
                  }
                  alt="Clinatix"
                />
              </div>
              <h2 className="title ps-3">Forgot Password</h2>
              <Form
                form={form}
                labelCol={{
                  span: 24,
                }}
                onFinish={(data) => {
                  props.manageAuthAsync({
                    request_type: "FORGOT_PASSWORD",
                    ...data,
                  });
                }}
              >
                <Form.Item
                  name="Email"
                  label="Registered Email"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: "Email is required",
                    },
                    {
                      type: "email",
                      message: "Input should be email",
                    },
                  ]}
                >
                  <Input type="email" placeholder="Enter Email" />
                </Form.Item>

                <div
                  className="d-flex mt-5 pt-3 text-center"
                  style={{
                    justifyContent: "space-between",
                    gap: "10px",
                  }}
                >
                  <Button
                    loading={
                      auth_loading && auth_request_type == "FORGOT_PASSWORD"
                    }
                    className="btn-primary"
                    size="large"
                    type="primary"
                    htmlType="submit"
                  >
                    SEND EMAIL
                  </Button>
                  <Link to="/login">
                    <Button
                      loading={
                        auth_loading && auth_request_type == "FORGOT_PASSWORD"
                      }
                      className="btn-primary"
                      size="large"
                      type="primary"
                      htmlType="submit"
                    >
                      CANCEL
                    </Button>
                  </Link>
                </div>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </LoginContainer>
  );
};
const mapStatetoProps = ({ auth_reducer }) => {
  return { auth_reducer };
};
const mapDispatchtoProps = {
  manageAuthAsync: manageAuthRequest,
};
export default connect(mapStatetoProps, mapDispatchtoProps)(Forgot);

const LoginContainer = styled.div`
  .bg-image {
    background: transparent
      url("https://img.freepik.com/free-vector/big-isolated-employee-working-office-workplace-flat-illustration_1150-41780.jpg")
      no-repeat left;
    width: 100%;
    height: 100vh;
    display: block;
    background-size: contain;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
  }

  .compony-logo {
    margin-bottom: 70px;
  }

  .compony-logo img {
    display: block;
    margin: auto;
    height: 100px;
  }

  .form-label {
    font-size: 16px;
    color: #757575;
    font-weight: 600;
  }

  .form-control::placeholder {
    color: #d2d2d2;
  }

  .title {
    font-size: 36px;
    padding-bottom: 30px;
    border-bottom: 1px solid #707070;
    margin-bottom: 70px;
  }

  .login-form {
    max-width: 700px;
    z-index: 999;
  }

  .shape-image {
    position: relative;

    &::before {
      content: "";
      background: transparent url("images/trigle-shape.png") no-repeat right;
      width: 100%;
      height: 491px;
      display: block;
      background-size: contain;
      position: absolute;
      right: 0;
      height: 490px;
      top: 50px;
    }
  }

  .form-group {
    margin-bottom: 40px;
  }

  .login-form .form-control {
    height: 60px;
    border-radius: 0;
    font-size: 18px;
  }

  .forgot-link {
    font-weight: 600;
    color: #e16c20;
  }

  .btn-primary {
    width: 200px !important;
  }

  .btn-primary:hover {
    background: #249edc;
    border-color: #249edc;
  }

  .login-form {
    .input-group .btn {
      height: 60px !important;
      img {
        max-width: 32px;
      }
    }
  }

  .input-group .btn {
    position: absolute;
    right: 0;
    border: 0;
    background: transparent !important;
    z-index: 9;
  }
`;
