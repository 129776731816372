import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { objectToQueryString } from "../../helpers/utils";

import { GET, POST, DELETE, LIST, PATCH, FORMDATA_POST } from "../api";
import { MANAGE_DASHBOARD_REQUEST } from "../actions";
import {
  manageDashboardError,
  manageDashboardSuccess,
  manageReportError,
  manageReportSuccess,
} from "./action";

export function* watchManageDashboard() {
  yield takeLatest(MANAGE_DASHBOARD_REQUEST, manageDashboardAsync);
}

function* manageDashboardAsync({ payload, request_type }) {
  let url, response;
  try {
    if (request_type == "DASHBOARD_LIST") {
      url = `/dxdashboard/UserReports?${objectToQueryString(payload)}`;
      response = yield call(GET, url, payload);
    } else if (request_type == "DELETE_DASHBOARD") {
      url = `/dxdashboard`;
      const { request_type, ids } = payload;
      response = yield call(DELETE, url, ids);
    } else if (request_type == "CLONE_DASHBOARD") {
      url = `/dxdashboard/clone`;
      response = yield call(POST, url, payload);
    } else if (request_type == "ADMIN_DASHBOARD") {
      url = `/admin/Dashboard`;
      response = yield call(GET, url, payload);
    } else {
      yield put(manageDashboardError("Invalid URL"));
    }
    if (response && response.is_error) {
      yield put(manageDashboardError(response.message));
    } else {
      yield put(manageDashboardSuccess(response, request_type));
    }
  } catch (error) {
    yield put(manageDashboardError(error));
  }
}

export default function* rootSaga() {
  yield all([fork(watchManageDashboard)]);
}
