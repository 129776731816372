import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { objectToQueryString } from "../../helpers/utils";

import { GET, POST, DELETE, LIST, PATCH, FORMDATA_POST } from "../api";
import { MANAGE_JOB_REQUEST, MANAGE_SCHEDULER_REQUEST } from "../actions";
import {
  manageJobError,
  manageJobSuccess,
  manageSchedulerError,
  manageSchedulerSuccess,
} from "./action";

export function* watchManageScheduler() {
  yield takeLatest(MANAGE_SCHEDULER_REQUEST, manageSchedulerAsync);
}

function* manageSchedulerAsync({ payload, request_type }) {
  let url, response;
  try {
    if (request_type == "TIMEZONES") {
      url = `/report/TimeZones`;
      response = yield call(GET, url, payload);
    } else if (request_type == "ADD_SCHEDULE_JOB") {
      url = `/Scheduler`;
      response = yield call(POST, url, payload);
    } else if (request_type == "EDIT_SCHEDULE_JOB") {
      url = `/Scheduler`;
      response = yield call(PATCH, url, payload);
    } else if (request_type == "SCHEDULE_INFO") {
      url = `/Scheduler/Job/${payload.id}`;
      response = yield call(GET, url, payload);
    } else if (request_type == "PAUSE_JOB") {
      url = `/Scheduler/Pause`;
      response = yield call(PATCH, url, payload.ids);
    } else if (request_type == "RESUME_JOB") {
      url = `/Scheduler/Resume`;
      response = yield call(PATCH, url, payload.ids);
    } else if (request_type == "JOB_HISTORY") {
      url = `/Scheduler/Job/${payload.job_id}/History`;
      response = yield call(GET, url, payload);
    } else if (request_type == "RUN_NOW") {
      url = `/Scheduler/${payload.type}/run/${payload.job_id}`;
      response = yield call(GET, url, payload);
    } else {
      yield put(manageSchedulerError("Invalid URL"));
    }
    if (response && response.is_error) {
      yield put(manageSchedulerError(response.message));
    } else {
      yield put(manageSchedulerSuccess(response, request_type));
    }
  } catch (error) {
    yield put(manageSchedulerError(error));
  }
}

export function* watchManageJob() {
  yield takeLatest(MANAGE_JOB_REQUEST, manageJobAsync);
}

function* manageJobAsync({ payload, request_type }) {
  let url, response;
  try {
    if (request_type == "JOB_LIST") {
      url = `/Scheduler/Job`;
      response = yield call(GET, url, payload);
    } else {
      yield put(manageJobError("Invalid URL"));
    }
    if (response && response.is_error) {
      yield put(manageJobError(response.message));
    } else {
      yield put(manageJobSuccess(response, request_type));
    }
  } catch (error) {
    yield put(manageJobError(error));
  }
}

export default function* rootSaga() {
  yield all([fork(watchManageScheduler), fork(watchManageJob)]);
}
