import { useLocation, Link } from "react-router-dom";
import { SidebarConfig } from "../../helpers/sidebar-config";
import styled from "styled-components";
const Sidebar = () => {
  const location = useLocation();

  const isActive = (itemPath, currentPath) => {
    // return currentPath.startsWith(itemPath);
    return itemPath == location.pathname;
  };
  return (
    <SideBarContainer>
      <div className="sidebar">
        <ul className="nav flex-column">
          {SidebarConfig?.map((item) => {
            return (
              <li className="nav-item" key={item?.name}>
                <Link
                  key={item?.path}
                  to={item?.path}
                  className={`nav-link ${
                    isActive(item.path, location.pathname) ? "active" : ""
                  }`}
                >
                  <img src={item?.icon} alt={item?.name} className="nav-img" />
                  <span className="d-block pt-2">{item?.name}</span>
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </SideBarContainer>
  );
};

export default Sidebar;

const SideBarContainer = styled.div`
  .sidebar {
    height: 100%;
    width: 100%;
    position: fixed;
    max-width: 100px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 6px #5b5b5b1c;
    border: 1px solid #f1f1f1;
    overflow-y: auto;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none;

    .nav .nav-link {
      color: #484848;
      font-weight: 500;
      text-align: center;
      padding: 5px;
      border-left: 4px solid transparent;
    }

    .nav .nav-item {
      position: relative;
      margin: 8px 5px;
    }

    .nav .nav-item::after {
      content: "";
      width: calc(100% - 20px);
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 6px #5b5b5b1c;
      border: 1px solid #f1f1f1;
      display: block;
      bottom: -10px;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
    }

    .nav .nav-item:hover .nav-link,
    .nav .nav-item .nav-link.active {
      background-color: #e1f9f8;
      border-left-color: #06a1a4;
    }

    .nav .nav-item:hover .nav-link img,
    .nav .nav-item .nav-link.active img {
      opacity: 1;
    }

    .nav-item .nav-img {
      max-width: 32px;
      width: auto;
      height: 100%;
      opacity: 0.7;
    }

    .nav-item span {
      font-size: 12px;
    }
  }
`;
