import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { objectToQueryString } from "../../helpers/utils";
import { MANAGE_USER_REQUEST } from "../actions";
import { manageUserError, manageUserSuccess } from "./action";
import { GET, POST, DELETE, LIST, PATCH, FORMDATA_POST, LOGIN } from "../api";

export function* watchManageUser() {
  yield takeLatest(MANAGE_USER_REQUEST, manageuserAsync);
}

function* manageuserAsync({ payload, request_type }) {
  let url, response;
  try {
    if (request_type == "LOGIN") {
      url = `/user/login`;
      response = yield call(LOGIN, url, payload);
    } else if (request_type == "USER_LIST") {
      url = `/user/list`;
      response = yield call(GET, url, payload);
    } else if (request_type == "ADD_USER") {
      url = `/user`;
      response = yield call(POST, url, payload);
    } else if (request_type == "EDIT_USER") {
      url = `/user`;
      response = yield call(PATCH, url, payload);
    } else {
      yield put(manageUserError("Invalid URL"));
    }
    if (response && response.is_error) {
      yield put(manageUserError(response.message));
    } else {
      yield put(manageUserSuccess(response, request_type));
    }
  } catch (error) {
    yield put(manageUserError(error));
  }
}

export default function* rootSaga() {
  yield all([fork(watchManageUser)]);
}
