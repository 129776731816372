import {
  INIT_URL,
  MANAGE_USER_ERROR,
  MANAGE_USER_REQUEST,
  MANAGE_USER_SUCCESS,
} from "../actions";

const INIT_STATE = {
  user_loading: false,
  user_success: null,
  user_error: null,
  user_action: null,
  user_request_type: null,
  initURL: "",
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case MANAGE_USER_REQUEST:
      return {
        ...state,
        user_loading: true,
        user_error: null,
        user_success: null,
        user_action: action.type,
        user_request_type: action.request_type,
      };
    case MANAGE_USER_SUCCESS:
      if (action.request_type == "LOGIN") {
        return {
          ...state,
          user_loading: false,
          user_success: true,
          login_info: action.payload,
        };
      } else if (action.request_type == "USER_LIST") {
        return {
          ...state,
          user_loading: false,
          user_success: true,
          user_list: action.payload,
        };
      } else if (
        action.request_type == "ADD_USER" ||
        action.request_type == "EDIT_USER"
      ) {
        return {
          ...state,
          user_loading: false,
          user_success: true,
        };
      }
    case MANAGE_USER_ERROR:
      return {
        ...state,
        user_loading: false,
        user_error: action.payload,
      };
    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload,
      };
    }
    default:
      return { ...state };
  }
};
