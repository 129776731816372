import {
  MANAGE_DASHBOARD_ERROR,
  MANAGE_DASHBOARD_REQUEST,
  MANAGE_DASHBOARD_SUCCESS,
} from "../actions";

const INIT_STATE = {
  dashboard_loading: false,
  dashboard_success: null,
  dashboard_error: null,
  dashboard_action: null,
  dashboard_request_type: null,
  dashboard_list: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case MANAGE_DASHBOARD_REQUEST:
      return {
        ...state,
        dashboard_loading: true,
        dashboard_error: null,
        dashboard_success: null,
        dashboard_action: action.type,
        dashboard_request_type: action.request_type,
      };
    case MANAGE_DASHBOARD_SUCCESS:
      if (action.request_type == "DASHBOARD_LIST") {
        return {
          ...state,
          dashboard_loading: false,
          dashboard_success: true,
          dashboard_list: action.payload,
        };
      } else if (action.request_type == "DELETE_DASHBOARD") {
        return {
          ...state,
          dashboard_loading: false,
          dashboard_success: true,
        };
      } else if (action.request_type == "CLONE_DASHBOARD") {
        return {
          ...state,
          dashboard_loading: false,
          dashboard_success: true,
        };
      } else if (action.request_type == "ADMIN_DASHBOARD") {
        return {
          ...state,
          dashboard_loading: false,
          dashboard_success: true,
          admin_dashboard: action.payload,
        };
      }
    case MANAGE_DASHBOARD_ERROR:
      return {
        ...state,
        dashboard_loading: false,
        dashboard_error: action.payload,
      };

    default:
      return { ...state };
  }
};
