import { Link, useLocation, useHistory } from "react-router-dom";
import { pageRoutes, tokens } from "../../helpers/constants";
import { Button, Form, Input } from "antd";
import { useEffect, useState } from "react";
import { getSearchQuery } from "../../helpers/shared-method";
import styled from "styled-components";
import UserInfo from "../../helpers/user_info";
const TopBar = () => {
  const location = useLocation();
  const history = useHistory();
  const [searchText, setSearchText] = useState("");
  const searchQuery = getSearchQuery(location.search);

  useEffect(() => {
    if (searchQuery) {
      setSearchText(searchQuery);
    }
  }, [searchQuery]);

  const onSearchFilter = (e) => {
    e.preventDefault();
    setSearchText(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default action if Enter key is pressed
      setSearchText(e.target.value);
      history.push(`${pageRoutes.SEARCH}?search=${e.target.value}`);
    }
  };

  const onLogout = () => {
    localStorage.removeItem(tokens.AUTH_TOKEN);
    localStorage.removeItem(tokens.EMAIL);
    localStorage.removeItem(tokens.NAME);
    history.push(pageRoutes.LOGIN);
  };
  return (
    <TopBarContainer>
      <div className="topbar">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-6">
              <div className="d-flex align-items-center logo-container">
                <Link className="d-block topBar-logo" to="/">
                  <img
                    src={
                      // "https://prabhuclinics.medicorm.com/assets/images/brand.png"
                      "assets/images/logo.png"

                    }
                    alt="P"
                  />
                </Link>

                {/* <Input
                  type="search"
                  value={searchText}
                  placeholder="Search Jobs/Template"
                  onChange={(e) => onSearchFilter(e)}
                  onKeyPress={handleKeyPress}
                  className="rounded-0"
                  suffix={
                    <svg
                      fill="#ffffff"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 30 30"
                      width="20px"
                      height="20px"
                    >
                      <path d="M 13 3 C 7.4889971 3 3 7.4889971 3 13 C 3 18.511003 7.4889971 23 13 23 C 15.396508 23 17.597385 22.148986 19.322266 20.736328 L 25.292969 26.707031 A 1.0001 1.0001 0 1 0 26.707031 25.292969 L 20.736328 19.322266 C 22.148986 17.597385 23 15.396508 23 13 C 23 7.4889971 18.511003 3 13 3 z M 13 5 C 17.430123 5 21 8.5698774 21 13 C 21 17.430123 17.430123 21 13 21 C 8.5698774 21 5 17.430123 5 13 C 5 8.5698774 8.5698774 5 13 5 z" />
                    </svg>
                  }
                /> */}
              </div>
            </div>
            <div className="col-6">
              <ul className="nav justify-content-end align-items-center">
                <li className="me-2">
                  <Link to={pageRoutes.SETTINGS}>
                    <img src={"/assets/images/setting.svg"} alt="Setting" />
                  </Link>
                </li>
                <li className="d-flex align-items-center me-2">
                  {/* <img src={profile} alt="Setting" className="profile" /> */}
                  <span className="name">{UserInfo.getName()}</span>
                </li>
                <li className="d-flex align-items-center">
                  <Button className="logout-icon" onClick={onLogout}>
                    <img
                      src={"/assets/images/logout-icon.svg"}
                      alt="Logout Icon"
                    />
                  </Button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </TopBarContainer>
  );
};

export default TopBar;

const TopBarContainer = styled.div`
  .logout-icon {
    border: none;
  }
  .logout-icon img {
    height: 30px;
  }

  .topBar-logo img {
    height: 60px;
  }
  .logo-container {
    gap: 20px;
  }
`;
