import { pageRoutes } from "../helpers/constants";
import UserInfo from "./user_info";

const adminMenu = [
  {
    name: "Dashboard",
    icon: "/assets/images/dashboard.svg",
    path: pageRoutes.DASHBOARD,
  },
  {
    name: "Reports",
    icon: "/assets/images/reports.svg",
    path: pageRoutes.REPORTS,
  },

  {
    name: "Scheduler",
    icon: "/assets/images/scheduler.svg",
    path: pageRoutes.SCHEDULER,
  },
  {
    name: "User Management",
    icon: "/assets/images/user-management.png",
    path: pageRoutes.USER_MANAGEMENT,
  },
  {
    name: "Configure",
    icon: "/assets/images/configure.png",
    path: pageRoutes.CONFIGURE,
  },
];

const designerMenu = [
  {
    name: "Reports",
    icon: "/assets/images/reports.svg",
    path: pageRoutes.REPORTS,
  },

  {
    name: "Scheduler",
    icon: "/assets/images/scheduler.svg",
    path: pageRoutes.SCHEDULER,
  },
];
export const SidebarConfig =
  UserInfo.getRole() == "Admin" ? adminMenu : designerMenu;
