export const pageRoutes = {
  LOGIN: "/auth/login",
  FORGOT_PASSWORD: "/forgot",
  CHANGED_PASSWORD_SUCCESSFULLY: "/auth/change-password-successfully",
  CHANGED_PASSWORD: "/auth/change-password",
  DASHBOARD: "/dashboard",
  SEARCH: "/search",
  REPORTS: "/reports",
  DASHBOARD_LIST: "/dashboard/list",
  SCHEDULER: "/scheduler",
  REPORT_DESIGNER: "/reports/designer",
  CONFIGURE: "/configure",
  USER_MANAGEMENT: "/user",
  SETTINGS: "/settings",
  MODELS: "/models",
};
export const tokens = {
  AUTH_TOKEN: "token",
  EMAIL: "email",
  NAME: "name",
  RANDOM_PASSWORD: "random_password",
  IS_FORGOT: "is_forgot",
};

export const notificationType = {
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warning",
};
