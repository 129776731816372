import React from "react";
import { footerText } from "../../util/config";
import App from "../../routes/index";
import { useRouteMatch } from "react-router-dom";
import Layout from "../Layout";

function MainApp() {
  const match = useRouteMatch();

  return (
    <Layout>
      <App match={match} />
    </Layout>
  );
}

export default MainApp;
