export const INIT_URL = "INIT_URL";

export const MANAGE_AUTH_REQUEST = "MANAGE_AUTH_REQUEST";
export const MANAGE_AUTH_SUCCESS = "MANAGE_AUTH_SUCCESS";
export const MANAGE_AUTH_ERROR = "MANAGE_AUTH_ERROR";

export const MANAGE_USER_REQUEST = "MANAGE_USER_REQUEST";
export const MANAGE_USER_SUCCESS = "MANAGE_USER_SUCCESS";
export const MANAGE_USER_ERROR = "MANAGE_USER_ERROR";

export const MANAGE_DATASOURCE_REQUEST = "MANAGE_DATASOURCE_REQUEST";
export const MANAGE_DATASOURCE_SUCCESS = "MANAGE_DATASOURCE_SUCCESS";
export const MANAGE_DATASOURCE_ERROR = "MANAGE_DATASOURCE_ERROR";

export const MANAGE_REPORT_REQUEST = "MANAGE_REPORT_REQUEST";
export const MANAGE_REPORT_SUCCESS = "MANAGE_REPORT_SUCCESS";
export const MANAGE_REPORT_ERROR = "MANAGE_REPORT_ERROR";

export const MANAGE_DISTRIBUTION_REQUEST = "MANAGE_DISTRIBUTION_REQUEST";
export const MANAGE_DISTRIBUTION_SUCCESS = "MANAGE_DISTRIBUTION_SUCCESS";
export const MANAGE_DISTRIBUTION_ERROR = "MANAGE_DISTRIBUTION_ERROR";

export const MANAGE_SCHEDULER_REQUEST = "MANAGE_SCHEDULER_REQUEST";
export const MANAGE_SCHEDULER_SUCCESS = "MANAGE_SCHEDULER_SUCCESS";
export const MANAGE_SCHEDULER_ERROR = "MANAGE_SCHEDULER_ERROR";

export const MANAGE_JOB_REQUEST = "MANAGE_JOB_REQUEST";
export const MANAGE_JOB_SUCCESS = "MANAGE_JOB_SUCCESS";
export const MANAGE_JOB_ERROR = "MANAGE_JOB_ERROR";

export const MANAGE_DASHBOARD_REQUEST = "MANAGE_DASHBOARD_REQUEST";
export const MANAGE_DASHBOARD_SUCCESS = "MANAGE_DASHBOARD_SUCCESS";
export const MANAGE_DASHBOARD_ERROR = "MANAGE_DASHBOARD_ERROR";

export const MANAGE_MASTER_REQUEST = "MANAGE_MASTER_REQUEST";
export const MANAGE_MASTER_SUCCESS = "MANAGE_MASTER_SUCCESS";
export const MANAGE_MASTER_ERROR = "MANAGE_MASTER_ERROR";

export * from "./auth/action";
export * from "./user/action";
export * from "./configure/action";
export * from "./report/action";
