import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import auth_reducer from "./auth/reducer";

import user_reducer from "./user/reducer";
import configure_reducer from "./configure/reducer";
import report_reducer from "./report/reducer";
import scheduler_reducer from "./scheduler/reducer";
import dashboard_reducer from "./dashboard/reducer";
import master_reducer from "./master/reducer";

const createBrowserHistory = require("history").createBrowserHistory;
const history = createBrowserHistory();
const reducers = combineReducers({
  auth_reducer,
  user_reducer,
  configure_reducer,
  report_reducer,
  scheduler_reducer,
  dashboard_reducer,
  master_reducer,
  router: connectRouter(history),
});

export default reducers;
