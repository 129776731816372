import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { objectToQueryString } from "../../helpers/utils";

import { GET, POST, DELETE, LIST, PATCH, FORMDATA_POST } from "../api";
import { MANAGE_REPORT_REQUEST } from "../actions";
import { manageReportError, manageReportSuccess } from "./action";

export function* watchManageReport() {
  yield takeLatest(MANAGE_REPORT_REQUEST, manageReportAsync);
}

function* manageReportAsync({ payload, request_type }) {
  let url, response;
  try {
    if (request_type == "REPORT_LIST") {
      url = `/report/UserReports?${objectToQueryString(payload)}`;
      response = yield call(GET, url, payload);
    } else if (request_type == "DELETE_REPORT") {
      url = `/report`;
      const { request_type, ids } = payload;
      response = yield call(DELETE, url, ids);
    } else if (request_type == "CLONE_REPORT") {
      url = `/report/clone`;
      response = yield call(POST, url, payload);
    } else if (request_type == "SAVE_WORKSPACE_PERMISSION") {
      url = `/report/WorkspacePermission`;
      response = yield call(POST, url, payload);
    } else if (request_type == "WORKSPACE_PERMISSION_LIST") {
      url = `/report/WorkspacePermission/${payload.report_id}`;
      response = yield call(GET, url, payload);
    } else if (request_type == "SAVE_USER_PERMISSION") {
      url = `/report/UserPermission`;
      response = yield call(POST, url, payload);
    } else if (request_type == "USER_PERMISSION_LIST") {
      url = `/report/UserPermission/${payload.report_id}`;
      response = yield call(GET, url, payload);
    } else {
      yield put(manageReportError("Invalid URL"));
    }
    if (response && response.is_error) {
      yield put(manageReportError(response.message));
    } else {
      yield put(manageReportSuccess(response, request_type));
    }
  } catch (error) {
    yield put(manageReportError(error));
  }
}

export default function* rootSaga() {
  yield all([fork(watchManageReport)]);
}
