import {
  MANAGE_MASTER_ERROR,
  MANAGE_MASTER_REQUEST,
  MANAGE_MASTER_SUCCESS,
} from "../actions";

const INIT_STATE = {
  master_loading: false,
  master_success: null,
  master_error: null,
  master_action: null,
  master_request_type: null,
  WORKSPACE_LIST: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case MANAGE_MASTER_REQUEST:
      return {
        ...state,
        master_loading: true,
        master_error: null,
        master_success: null,
        master_action: action.type,
        master_request_type: action.request_type,
      };
    case MANAGE_MASTER_SUCCESS:
      if (action.request_type == "WORKSPACE_LIST") {
        return {
          ...state,
          master_loading: false,
          master_success: true,
          workspace_list: action.payload || [],
        };
      } else if (
        action.request_type == "ADD_WORKSPACE" ||
        action.request_type == "EDIT_WORKSPACE"
      ) {
        return {
          ...state,
          master_loading: false,
          master_success: true,
        };
      } else if (action.request_type == "ACTIVE_WORKSPACE") {
        return {
          ...state,
          master_loading: false,
          master_success: true,
        };
      } else if (action.request_type == "DELETE_WORKSPACE") {
        return {
          ...state,
          master_loading: false,
          master_success: true,
        };
      } else if (action.request_type == "GET_WORKSPACE") {
        return {
          ...state,
          master_loading: false,
          master_success: true,
          workspace: action.payload,
        };
      }
    case MANAGE_MASTER_ERROR:
      return {
        ...state,
        master_loading: false,
        master_error: action.payload,
      };

    default:
      return { ...state };
  }
};
