import { all } from "redux-saga/effects";
import authSaga from "./auth/saga";

import userSaga from "./user/saga";
import configureSaga from "./configure/saga";
import reportSaga from "./report/saga";
import schedulerSaga from "./scheduler/saga";
import dashboardSaga from "./dashboard/saga";
import masterSaga from "./master/saga";

export default function* rootSaga() {
  yield all([
    authSaga(),
    reportSaga(),
    userSaga(),
    configureSaga(),
    schedulerSaga(),
    dashboardSaga(),
    masterSaga(),
  ]);
}
