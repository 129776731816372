import { Footer } from "antd/es/layout/layout";
import { footerText } from "../../util/config";
import Sidebar from "./SideBar";
import Topbar from "./TopBar";
import styled from "styled-components";
const Layout = ({ children }) => {
  return (
    <LayourWrapper>
      <Topbar key="topbar" />
      <div className="d-flex align-items-stretch wrapper">
        <Sidebar key="sidebar" />
        <main className="main">{children}</main>
      </div>
    </LayourWrapper>
  );
};

export default Layout;

const LayourWrapper = styled.div`
  .wrapper {
    flex-direction: row;
    height: auto;
    min-height: calc(100vh - 70px);
  }

  .main {
    width: calc(100% - 100px);
    padding: 22px 22px;
    margin-left: 100px;
  }
`;
