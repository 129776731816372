import React, { Suspense, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { NotFound } from "./404";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
dayjs.extend(dayjsPluginUTC);

const Dashboard = React.lazy(() => import("./dashboard"));
const Configure = React.lazy(() => import("./configure"));
const Report = React.lazy(() => import("./report"));
const Scheduler = React.lazy(() => import("./scheduler"));
const User = React.lazy(() => import("./user"));
const MasterData = React.lazy(() => import("./master"));
function Index(props) {
  useEffect(() => {
    window.onkeypress = (e) => {
      if (e.key == "Enter") return false;
    };
  }, []);

  return (
    <div>
      <Suspense fallback={<div className="loading" />}>
        <Switch>
          <Route exact key={"/"} path={`/`} component={Dashboard} />
          <Route key={"dashboard"} path={`/dashboard`} component={Dashboard} />
          <Route key={"configure"} path={`/configure`} component={Configure} />
          <Route key={"report"} path={`/reports`} component={Report} />
          <Route key={"scheduler"} path={`/scheduler`} component={Scheduler} />
          <Route key={"user"} path={`/user`} component={User} />
          <Route key={"master"} path={`/master`} component={MasterData} />

          <Route
            key={"404"}
            path={`/404`}
            component={(props) => <NotFound {...props} />}
          />
          <Redirect to={"/404"} />
        </Switch>
      </Suspense>
    </div>
  );
}

export default Index;
