import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { objectToQueryString } from "../../helpers/utils";

import { GET, POST, DELETE, LIST, PATCH, FORMDATA_POST } from "../api";
import { MANAGE_MASTER_REQUEST } from "../actions";
import { manageMasterError, manageMasterSuccess } from "./action";

export function* watchManageMaster() {
  yield takeLatest(MANAGE_MASTER_REQUEST, manageMasterAsync);
}

function* manageMasterAsync({ payload, request_type }) {
  let url, response;
  try {
    if (request_type == "WORKSPACE_LIST") {
      url = `/master/Workspace?${objectToQueryString(payload)}`;
      response = yield call(GET, url, payload);
    } else if (request_type == "ADD_WORKSPACE") {
      url = `/master/Workspace`;
      response = yield call(POST, url, payload);
    } else if (request_type == "EDIT_WORKSPACE") {
      url = `/master/Workspace`;
      response = yield call(PATCH, url, payload);
    } else if (request_type == "ACTIVE_WORKSPACE") {
      url = `/master/Workspace/active`;
      response = yield call(PATCH, url, payload);
    } else if (request_type == "DELETE_WORKSPACE") {
      url = `/master/Workspace`;
      response = yield call(DELETE, url, payload);
    } else if (request_type == "GET_WORKSPACE") {
      url = `/master/Workspace/${payload.id}`;
      response = yield call(GET, url, payload);
    } else {
      yield put(manageMasterError("Invalid URL"));
    }
    if (response && response.is_error) {
      yield put(manageMasterError(response.message));
    } else {
      yield put(manageMasterSuccess(response, request_type));
    }
  } catch (error) {
    yield put(manageMasterError(error));
  }
}

export default function* rootSaga() {
  yield all([fork(watchManageMaster)]);
}
