import {
  INIT_URL,
  MANAGE_USER_ERROR,
  MANAGE_USER_REQUEST,
  MANAGE_USER_SUCCESS,
} from "../actions";

export const manageUserRequest = (request) => ({
  type: MANAGE_USER_REQUEST,
  payload: request,
  request_type: request.request_type,
});
export const manageUserSuccess = (response, request_type) => ({
  type: MANAGE_USER_SUCCESS,
  payload: response,
  request_type: request_type,
});
export const manageUserError = (message) => ({
  type: MANAGE_USER_ERROR,
  payload: message,
});
