import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { objectToQueryString } from "../../helpers/utils";
import {
  MANAGE_DATASOURCE_REQUEST,
  MANAGE_DISTRIBUTION_REQUEST,
} from "../actions";
import { GET, POST, DELETE, LIST, PATCH, FORMDATA_POST } from "../api";
import {
  manageDataSourceError,
  manageDataSourceSuccess,
  manageDistributionError,
  manageDistributionSuccess,
} from "./action";

export function* watchManageDataSource() {
  yield takeLatest(MANAGE_DATASOURCE_REQUEST, manageuserAsync);
}

function* manageuserAsync({ payload, request_type }) {
  let url, response;
  try {
    if (request_type == "ADD_DATASOURCE") {
      url = `/configure/datasource`;
      response = yield call(POST, url, payload);
    } else if (request_type == "EDIT_DATASOURCE") {
      url = `/configure/datasource`;
      response = yield call(PATCH, url, payload);
    } else if (request_type == "USER_DATASOURCE") {
      url = `/configure/userdataSource`;
      response = yield call(GET, url, payload);
    } else if (request_type == "DATASOURCE_INFO") {
      url = `/configure/dataSource/${payload.id}`;
      response = yield call(GET, url, payload);
    } else {
      yield put(manageDataSourceError("Invalid URL"));
    }
    if (response && response.is_error) {
      yield put(manageDataSourceError(response.message));
    } else {
      yield put(manageDataSourceSuccess(response, request_type));
    }
  } catch (error) {
    yield put(manageDataSourceError(error));
  }
}

export function* watchManageDistribution() {
  yield takeLatest(MANAGE_DISTRIBUTION_REQUEST, manageDistributionAsync);
}

function* manageDistributionAsync({ payload, request_type }) {
  let url, response;
  try {
    if (request_type == "ADD_MAIL_DISTRIBUTION") {
      url = `/configure/Smtp`;
      response = yield call(POST, url, payload);
    } else if (request_type == "EDIT_MAIL_DISTRIBUTION") {
      url = `/configure/Smtp`;
      response = yield call(PATCH, url, payload);
    } else if (request_type == "SET_DEFAULT_SMTP") {
      url = `/configure/Smtp/${payload.id}`;
      response = yield call(PATCH, url, payload);
    } else if (request_type == "SMTP_LIST") {
      url = `/configure/Smtp`;
      response = yield call(GET, url, payload);
    } else if (request_type == "SMTP_INFO") {
      url = `/configure/Smtp/${payload.id}`;
      response = yield call(GET, url, payload);
    } else if (request_type == "ADD_SFTP_DISTRIBUTION") {
      url = `/configure/Sftp`;
      response = yield call(POST, url, payload);
    } else if (request_type == "EDIT_SFTP_DISTRIBUTION") {
      url = `/configure/Sftp`;
      response = yield call(PATCH, url, payload);
    } else if (request_type == "SFTP_LIST") {
      url = `/configure/Sftp`;
      response = yield call(GET, url, payload);
    } else if (request_type == "SFTP_INFO") {
      url = `/configure/Sftp/${payload.id}`;
      response = yield call(GET, url, payload);
    } else {
      yield put(manageDistributionError("Invalid URL"));
    }
    if (response && response.is_error) {
      yield put(manageDistributionError(response.message));
    } else {
      yield put(manageDistributionSuccess(response, request_type));
    }
  } catch (error) {
    yield put(manageDistributionError(error));
  }
}

export default function* rootSaga() {
  yield all([fork(watchManageDataSource), fork(watchManageDistribution)]);
}
