import {
  MANAGE_DATASOURCE_ERROR,
  MANAGE_DATASOURCE_REQUEST,
  MANAGE_DATASOURCE_SUCCESS,
  MANAGE_DISTRIBUTION_ERROR,
  MANAGE_DISTRIBUTION_REQUEST,
  MANAGE_DISTRIBUTION_SUCCESS,
} from "../actions";

export const manageDataSourceRequest = (request) => ({
  type: MANAGE_DATASOURCE_REQUEST,
  payload: request,
  request_type: request.request_type,
});
export const manageDataSourceSuccess = (response, request_type) => ({
  type: MANAGE_DATASOURCE_SUCCESS,
  payload: response,
  request_type: request_type,
});
export const manageDataSourceError = (message) => ({
  type: MANAGE_DATASOURCE_ERROR,
  payload: message,
});

export const manageDistributionRequest = (request) => ({
  type: MANAGE_DISTRIBUTION_REQUEST,
  payload: request,
  request_type: request.request_type,
});
export const manageDistributionSuccess = (response, request_type) => ({
  type: MANAGE_DISTRIBUTION_SUCCESS,
  payload: response,
  request_type: request_type,
});
export const manageDistributionError = (message) => ({
  type: MANAGE_DISTRIBUTION_ERROR,
  payload: message,
});
