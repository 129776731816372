import {
  MANAGE_DATASOURCE_ERROR,
  MANAGE_DATASOURCE_REQUEST,
  MANAGE_DATASOURCE_SUCCESS,
  MANAGE_DISTRIBUTION_ERROR,
  MANAGE_DISTRIBUTION_REQUEST,
  MANAGE_DISTRIBUTION_SUCCESS,
} from "../actions";

const INIT_STATE = {
  configure_loading: false,
  configure_success: null,
  configure_error: null,
  configure_action: null,
  configure_request_type: null,
  datasource_list: [],
  smtp_list: [],
  sftp_list: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case MANAGE_DATASOURCE_REQUEST:
      return {
        ...state,
        configure_loading: true,
        configure_error: null,
        configure_success: null,
        configure_action: action.type,
        configure_request_type: action.request_type,
      };
    case MANAGE_DATASOURCE_SUCCESS:
      if (
        action.request_type == "ADD_DATASOURCE" ||
        action.request_type == "EDIT_DATASOURCE"
      ) {
        return {
          ...state,
          configure_loading: false,
          configure_success: true,
          datasource: action.payload,
        };
      } else if (action.request_type == "USER_DATASOURCE") {
        return {
          ...state,
          configure_loading: false,
          configure_success: true,
          datasource_list: action.payload,
        };
      } else if (action.request_type == "DATASOURCE_INFO") {
        return {
          ...state,
          configure_loading: false,
          configure_success: true,
          datasource: action.payload,
        };
      }
    case MANAGE_DATASOURCE_ERROR:
      return {
        ...state,
        configure_loading: false,
        configure_error: action.payload,
      };

    case MANAGE_DISTRIBUTION_REQUEST:
      return {
        ...state,
        configure_loading: true,
        configure_error: null,
        configure_success: null,
        configure_action: action.type,
        configure_request_type: action.request_type,
      };
    case MANAGE_DISTRIBUTION_SUCCESS:
      if (
        action.request_type == "ADD_MAIL_DISTRIBUTION" ||
        action.request_type == "EDIT_MAIL_DISTRIBUTION" ||
        action.request_type == "ADD_SFTP_DISTRIBUTION" ||
        action.request_type == "EDIT_SFTP_DISTRIBUTION"
      ) {
        return {
          ...state,
          configure_loading: false,
          configure_success: true,
        };
      } else if (action.request_type == "SET_DEFAULT_SMTP") {
        return {
          ...state,
          configure_loading: false,
          configure_success: true,
        };
      } else if (action.request_type == "SMTP_LIST") {
        return {
          ...state,
          configure_loading: false,
          configure_success: true,
          smtp_list: action.payload,
        };
      } else if (action.request_type == "SFTP_LIST") {
        return {
          ...state,
          configure_loading: false,
          configure_success: true,
          sftp_list: action.payload,
        };
      } else if (action.request_type == "SMTP_INFO") {
        return {
          ...state,
          configure_loading: false,
          configure_success: true,
          smtp_info: action.payload,
        };
      } else if (action.request_type == "SFTP_INFO") {
        return {
          ...state,
          configure_loading: false,
          configure_success: true,
          sftp_info: action.payload,
        };
      }
    case MANAGE_DISTRIBUTION_ERROR:
      return {
        ...state,
        configure_loading: false,
        configure_error: action.payload,
      };

    default:
      return { ...state };
  }
};
