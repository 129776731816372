import {
  MANAGE_JOB_ERROR,
  MANAGE_JOB_REQUEST,
  MANAGE_JOB_SUCCESS,
  MANAGE_SCHEDULER_ERROR,
  MANAGE_SCHEDULER_REQUEST,
  MANAGE_SCHEDULER_SUCCESS,
} from "../actions";

const INIT_STATE = {
  scheduler_loading: false,
  scheduler_success: null,
  scheduler_error: null,
  scheduler_action: null,
  scheduler_request_type: null,
  scheduler_list: [],
  job_list: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case MANAGE_SCHEDULER_REQUEST:
      return {
        ...state,
        scheduler_loading: true,
        scheduler_error: null,
        scheduler_success: null,
        scheduler_action: action.type,
        scheduler_request_type: action.request_type,
      };
    case MANAGE_SCHEDULER_SUCCESS:
      if (
        action.request_type == "ADD_SCHEDULE_JOB" ||
        action.request_type == "EDIT_SCHEDULE_JOB" ||
        action.request_type == "PAUSE_JOB" ||
        action.request_type == "RESUME_JOB"
      ) {
        return {
          ...state,
          scheduler_loading: false,
          scheduler_success: true,
        };
      } else if (action.request_type == "TIMEZONES") {
        return {
          ...state,
          scheduler_loading: false,
          scheduler_success: true,
          timezones: action.payload,
        };
      } else if (action.request_type == "SCHEDULE_INFO") {
        return {
          ...state,
          scheduler_loading: false,
          scheduler_success: true,
          schedule_info: action.payload,
        };
      } else if (action.request_type == "JOB_HISTORY") {
        return {
          ...state,
          scheduler_loading: false,
          scheduler_success: true,
          job_history: action.payload,
        };
      } else if (action.request_type == "RUN_NOW") {
        return {
          ...state,
          scheduler_loading: false,
          scheduler_success: true,
        };
      }
    case MANAGE_SCHEDULER_ERROR:
      return {
        ...state,
        scheduler_loading: false,
        scheduler_error: action.payload,
      };

    case MANAGE_JOB_REQUEST:
      return {
        ...state,
        scheduler_loading: true,
        scheduler_error: null,
        scheduler_success: null,
        scheduler_action: action.type,
        scheduler_request_type: action.request_type,
      };
    case MANAGE_JOB_SUCCESS:
      if (action.request_type == "JOB_LIST") {
        return {
          ...state,
          scheduler_loading: false,
          scheduler_success: true,
          job_list: action.payload,
        };
      }
    case MANAGE_JOB_ERROR:
      return {
        ...state,
        scheduler_loading: false,
        scheduler_error: action.payload,
      };

    default:
      return { ...state };
  }
};
