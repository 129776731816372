import {
  MANAGE_REPORT_ERROR,
  MANAGE_REPORT_REQUEST,
  MANAGE_REPORT_SUCCESS,
} from "../actions";

const INIT_STATE = {
  report_loading: false,
  report_success: null,
  report_error: null,
  report_action: null,
  report_request_type: null,
  report_list: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case MANAGE_REPORT_REQUEST:
      return {
        ...state,
        report_loading: true,
        report_error: null,
        report_success: null,
        report_action: action.type,
        report_request_type: action.request_type,
      };
    case MANAGE_REPORT_SUCCESS:
      if (action.request_type == "REPORT_LIST") {
        return {
          ...state,
          report_loading: false,
          report_success: true,
          report_list: action.payload,
        };
      } else if (action.request_type == "DELETE_REPORT") {
        return {
          ...state,
          report_loading: false,
          report_success: true,
        };
      } else if (action.request_type == "CLONE_REPORT") {
        return {
          ...state,
          report_loading: false,
          report_success: true,
        };
      } else if (
        action.request_type == "SAVE_WORKSPACE_PERMISSION" ||
        action.request_type == "SAVE_USER_PERMISSION"
      ) {
        return {
          ...state,
          report_loading: false,
          report_success: true,
        };
      } else if (action.request_type == "WORKSPACE_PERMISSION_LIST") {
        return {
          ...state,
          report_loading: false,
          report_success: true,
          report_workspace_permissions: action.payload,
        };
      } else if (action.request_type == "USER_PERMISSION_LIST") {
        return {
          ...state,
          report_loading: false,
          report_success: true,
          report_user_permissions: action.payload,
        };
      }
    case MANAGE_REPORT_ERROR:
      return {
        ...state,
        report_loading: false,
        report_error: action.payload,
      };

    default:
      return { ...state };
  }
};
