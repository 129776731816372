import {
  MANAGE_MASTER_ERROR,
  MANAGE_MASTER_REQUEST,
  MANAGE_MASTER_SUCCESS,
} from "../actions";

export const manageMasterRequest = (request) => ({
  type: MANAGE_MASTER_REQUEST,
  payload: request,
  request_type: request.request_type,
});
export const manageMasterSuccess = (response, request_type) => ({
  type: MANAGE_MASTER_SUCCESS,
  payload: response,
  request_type: request_type,
});
export const manageMasterError = (message) => ({
  type: MANAGE_MASTER_ERROR,
  payload: message,
});
