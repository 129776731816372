import React, { useEffect } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { ConfigProvider } from "antd";
import { CheckAuth } from "../../helpers/utils";
import MainApp from "./MainApp";
import SignIn from "../../routes/auth/signin";
import Forgot from "../../routes/auth/forgot";

import { setInitUrl } from "../../redux/auth/action";
import { connect, useDispatch, useSelector } from "react-redux";

const RestrictedRoute = ({ component: Component, location, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      CheckAuth() == true ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/signin",
            state: { from: location },
          }}
        />
      )
    }
  />
);
const Index = (props) => {
  const match = useRouteMatch();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { auth_loading, token, initURL } = props.auth_reducer;

  useEffect(() => {
    if (initURL === "") {
      dispatch(setInitUrl(location.pathname));
    }
  }, []);
  useEffect(() => {
    if (!auth_loading) {
      if (!CheckAuth() && initURL == "/forgot") {
        history.push(initURL);
      } else if (!CheckAuth()) {
        history.push("/signin");
      } else if (initURL === "" || initURL === "/" || initURL === "/signin") {
        history.push("/");
      } else {
        if (initURL) history.push(initURL || "/");
      }
    }
  }, [auth_loading, token, initURL]);

  return (
    <ConfigProvider
      key="antd"
      theme={{
        token: {
          colorPrimary: "#00b6bd",
          colorInfo: "#00b6bd",
          colorSuccess: "#4caf50",
        },
      }}
    >
      <Switch key="main_routes">
        <Route key="signin" exact path="/signin" component={SignIn} />
        <Route key="forgot" exact path="/forgot" component={Forgot} />

        <RestrictedRoute
          key="mainapp"
          path={`${match.url}`}
          location={location}
          component={MainApp}
        />
      </Switch>
    </ConfigProvider>
  );
};

const mapStatetoProps = ({ auth_reducer }) => {
  return { auth_reducer };
};
export default connect(mapStatetoProps)(Index);
