import { jwtDecode as decode } from "jwt-decode";

class UserInfo {
  constructor() {
    const current_user = localStorage.getItem("token");
    let token = current_user;
    if (token) {
      const { name } = decode(token);
      this.name = name;
    } else {
      localStorage.removeItem("token");
      window.location.href = "/";
    }
  }

  static getAccessKey() {
    const current_user = localStorage.getItem("token");
    if (!current_user) return null;
    const { accesskey } = decode(current_user);
    return accesskey;
  }

  static getName() {
    const current_user = localStorage.getItem("token");
    if (!current_user) return null;
    const { name } = decode(current_user);
    return name;
  }
  static getWorkspace() {
    const current_user = localStorage.getItem("token");
    if (!current_user) return null;
    const { workspace_id } = decode(current_user);
    return workspace_id;
  }
  static getRole() {
    const current_user = localStorage.getItem("token");
    if (!current_user) return null;
    const { roles } = decode(current_user);
    return roles;
  }
}

export default UserInfo;
